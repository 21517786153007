import React, { useEffect, useState } from "react";

export default function SwiperSlide() {
  const [clients, setClients] = useState([]);
  const getSlides = async () => {
    const response = await fetch("/assets/img/clients/clients.json");
    const data = await response.json();
    setClients(data);
  };
  const getImage = (path) => {
    return "assets/img/clients/" + path;
  };
  useEffect(() => {
    if (clients.length === 0) getSlides();
  }, [clients]);

  return (
    <div className="swiper init-swiper">
      <div className="swiper-wrapper align-items-center">
        {clients.map((client, i) => (
          <div className="swiper-slide" key={i}>
            <img src={getImage(client)} className="img-fluid" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}
