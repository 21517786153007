import React, { useEffect, useState } from "react";

export default function Testimonial() {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    const response = await fetch("/assets/img/users/users.json");
    const data = await response.json();
    setUsers(data);
  };

  useEffect(() => {
    if (users.length === 0) getUsers();
  }, [users]);
  return (
    <div className="swiper init-swiper">
      <div className="swiper-wrapper">
        {users.map((user) => (
          <div className="swiper-slide" key={user.name}>
            <div className="testimonial-item">
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>{user.msg}</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
              <h3 className="text-end"> - {user.name}</h3>
            </div>
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
}
