import React, { useEffect, useState } from "react";

export default function Event() {
  const [event, setEvent] = useState([]);
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const getSlides = async () => {
    const response = await fetch("/assets/img/events/event.json");
    const data = await response.json();
    setEvent(() => data);
    setIsEventLoaded(true);
  };
  const getImage = (path) => {
    return "assets/img/events/" + path;
  };
  useEffect(() => {
      if(event.length === 0 && !isEventLoaded) getSlides();
  }, [isEventLoaded]);
  return (
    <div className="container">
      {event && event.length && (
        <div className="content row">
          <div className="d-flex flex-column justify-content-center">
            <div className="row">
              {event.map((e) => (
                <div
                  className="col-md-4 col-sm-6 gy-4 d-flex align-items-stretch"
                  key={e.img}
                >
                  <div
                    className="event-item"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      className="about-video-img img-fluid"
                      src={getImage(e.img)}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
