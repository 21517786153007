import React from "react";

export default function Modal({
  children,
  title,
  subTitle,
  open,
  handleClose,
}) {
  const handleCloseModal = (e) => {
    e.preventDefault();
    handleClose(false);
  };
  return (
    <>
      {open && (
        <div
          className="modal show fade"
          tabIndex="-1"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className={title ? "modal-content" : "modal-content no-title"}>
              <div className="modal-header">
                <div>
                  {title && (
                    <h4 className="modal-title" id="staticBackdropLabel">
                      {title}
                    </h4>
                  )}
                  {subTitle && <h5>{subTitle}</h5>}
                </div>
                <button
                  onClick={handleCloseModal}
                  type="button"
                  className="btn btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
