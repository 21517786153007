import React, { useEffect, useState } from "react";

export default function Gallery() {
  const [gallery, setGallery] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getSlides = async () => {
    const response = await fetch("/assets/img/gallery/gallery.json");
    const data = await response.json();
    setGallery(() => data);
    setIsLoaded(true);
  };
  
  const getImage = (path) => {
    return "assets/img/gallery/" + path;
  };
  useEffect(() => {
    if (gallery.length === 0 && !isLoaded) getSlides();
  }, [gallery, isLoaded]);
  return (
    <>
      <div id="portfolio" className="portfolio">
        <div
          className="isotope-layout"
          data-default-filter="*"
          data-layout="masonry"
          data-sort="original-order"
        >
          <div
            className="row isotope-container gy-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {gallery.map((img) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 portfolio-item isotope-item filter-app"
                key={img}
              >
                <div className="gallery-item">
                  <a
                    href={getImage(img)}
                    className="glightbox"
                    data-gallery="images-gallery"
                  >
                    <img src={getImage(img)} alt="" className="img-fluid" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
