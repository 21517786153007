import React, { useEffect, useState } from "react";
import Modal from "../popup/Modal";

export default function Member() {
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMembers] = useState({});
  const getSlides = async () => {
    const response = await fetch("/assets/img/members/member.json");
    const data = await response.json();
    setMembers(data);
  };

  useEffect(() => {
    if (members.length === 0) getSlides();
  }, [members]);

  const handleClick = (e, member) => {
    e.preventDefault();
    setSelectedMembers(member);
    setOpen(true);
  };

  return (
    <div style={{ margin: "0rem 2rem" }}>
      <div className="row gy-4">
        {members.map((member) => (
          <div
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            key={member.name}
          >
            <div className="team-member d-flex align-items-start">
              <div className="pic">
                <img src={member.img} className="img-fluid" alt="" />
              </div>
              <div className="member-wrapper">
                <div className="member-info">
                  <h4>{member.name}</h4>
                  <span>{member.des}</span>
                  <p>{member.org}</p>
                </div>
                <button
                  onClick={(e) => handleClick(e, member)}
                  className="read-more"
                >
                  <div className="btn-wrapper">
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        title={selectedMember.name}
        subTitle={selectedMember.des}
        open={open}
        handleClose={setOpen}
      >
        <div style={{ display: "flex" }}>
          <div>
            <img
              className="info-img"
              src={selectedMember.img}
              alt="profile"
            />
            <div>{selectedMember.info}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
